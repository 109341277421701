import PimplesTemplate from '@/modules/questionnaire/components/steps/questionnaire/pimples/pimples/PimplesTemplate';

export default {
  title: 'Steps/Pimples/Pimples/PimplesTemplate',
  component: PimplesTemplate
};

const createStory = props => () => ({
  components: { PimplesTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><pimples-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  proneToAcne: 'yes'
});
